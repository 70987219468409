export const getArticles = async (
	axios,
	params = {},
	path = "https://api.yourdictionary.com/cms/v1/wfa/category/.web/articles"
) => {
	return await axios
		.get(path, { params })
		.then((response) => {
			return response.status === 200 && response.data.status === 200
				? response.data
				: {};
		})
		.catch((err) => {
			console.error("Could not get articles list. ", err);
			return err.response.data;
		});
};

/**
 * @todo why do we have this AND `getCMSCopy` in `cmsCopyService.js`?
 */
export const getArticleData = async (axios, slug, previewParm, store) => {
	// Add a timestamp suffix for previews to bypass the Cloud Front chache.
	const version = previewParm === "true" ? `?v=${new Date().getTime()}` : "";
	const requestPath =
		store.getters["navigation/isWordleArticlesIndexPage"] ||
		store.getters["navigation/isWordleArticleDetailPage"]
			? "https://api.yourdictionary.com/cms/v1/wfa/article/.wordle.word-lists."
			: "https://api.yourdictionary.com/cms/v1/wfa/article/.web.";
	return await axios
		.get(`${requestPath}${slug}${version}`)
		.then((response) => {
			return response.status === 200 && response.data.status === 200
				? response.data
				: {};
		})
		.catch((err) => {
			console.error("Could not get article data. ", err);
			return err.response.data;
		});
};

export const importSeoArticle = async (axios, articleSlug) => {
	const url = `https://api.yourdictionary.com/cms/v1/wfa/article/.seo.${articleSlug}`; // Allows the cache to do its job.
	// const url = `https://api.yourdictionary.com/cms/v1/wfa/article/.seo.${articleSlug}?v=${Date.now()}`; // Disables the cache for test purposes.
	let data = { sections: [] };
	try {
		const axiosResponse = await axios.get(url);
		const article = axiosResponse.data.data;
		const body = processLinks(article.body);
		data = {
			metaTitle: article.metaTitle,
			metaDescription: article.metaDescription,
			sections: body.split("<hr>"),
		};
	} catch (error) {
		console.error(`Exception retrieving log copy article ${url}. ${error}`);
	}
	return data;
};

// Rewrites local links by removing the host component of the URL.
// Sample usage:
// console.log(`${processLinks('https://wordfinder-stg.yourdictionary.com/app/')}`);
// Input:  'https://wordfinder-stg.yourdictionary.com/app/'
// Output: '/app/'
function processLinks(link) {
	return link.replace(
		/* eslint-disable-next-line no-useless-escape */
		/href="https:\/\/wordfinder[\-a-z]*.yourdictionary.com\//g,
		'href="/'
	);
}
